import React from "react";
import ScrollToTop from "react-scroll-to-top";
const ScrollUp = () => {
    return(
        <>
            <ScrollToTop smooth />
        </>
    )
}

export default ScrollUp