import logo from './logo.svg';
import './App.css';
import WebApplication from './view/Services/WebApplication';
import Router from './view/Router/Router';


function App() {
  return (
      <>
        <Router/>
      </>
  );
}

export default App;
